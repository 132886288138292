<template>
  <Multiselect
    class="MultiSelectInput"
    v-bind="$props"
    :value="modelValue"
    @input="$emit('update:modelValue', $event)"
    @tag="addTag"
    :max="maxLength"
    :placeholder="placeholder"
    :isError="isError"
    :isErrorTag="isErrorTag"
    :allowSpaceAsEnter="allowSpaceAsEnter"
  />
</template>

<script type="text/babel">
import { Options, Vue } from "vue-class-component";
import Multiselect from "./multiselect";

@Options({
  components: { Multiselect },

  props: {
    modelValue: {},
    placeholder: {
      default: ""
    },
    options: {},
    label: {
      default: "name"
    },
    closeOnSelect: {
      default: false
    },
    selectLabel: {
      default: ""
    },
    trackBy: {
      default: "id"
    },
    multiple: {
      default: true
    },
    taggable: {
      default: false
    },
    hideSelected: {
      default: true
    },
    maxLength: {
      default: 0
    },
    isTextArea: {
      default: false
    },
    isError: {
      default: false
    },
    isErrorTag: {
      default: false
    },
    allowSpaceAsEnter: {
      type: Boolean,
      default: false
    }
    // You may need to add more inputs here - this above is just my preferred default config
  },

  emits: ["update:modelValue"] // add more events here as needed. Make sure to propagate them in your template
})
export default class MultiSelect extends Vue {
  props = this.$props;
  addTag(newTag) {
    const tag = {
      name: newTag,
      id: newTag,
      isError: false
    };
    this.props.options.push(tag);
    this.props.modelValue.push(tag);
  }
}
</script>
